import React from 'react';

export default function HowItWorksSectionHowItWorks4() {
    return (
        <React.Fragment>
            <>
                <section className="pt-24 pb-36 bg-white overflow-hidden">
  <div className="container px-4 mx-auto">
    <h2 className="mb-7 text-6xl md:text-8xl xl:text-10xl text-center font-bold font-heading tracking-px-n leading-none">Let’s see how it works</h2>
    <p className="mb-20 font-sans text-lg text-gray-900 text-center md:max-w-lg mx-auto">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elemen tum.</p>
    <div className="relative bg-no-repeat bg-center bg-cover bg-fixed overflow-hidden rounded-4xl" style={{height: 648, backgroundImage: 'url("flaro-assets/images/how-it-works/bg.png")'}}>
      <div className="absolute top-0 left-0 p-14 md:p-20 md:pb-0 overflow-y-auto h-full">
        <div className="flex flex-wrap">
          <div className="w-full">
            <div className="flex flex-wrap -m-3">
              <div className="w-auto p-3">
                <svg width={35} height={35} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="17.5" cy="17.5" r="17.5" fill="#4F46E5" />
                  <path d="M11.667 18.3333L15.0003 21.6666L23.3337 13.3333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <img className="mx-auto" src="flaro-assets/images/how-it-works/line3.svg" alt />
              </div>
              <div className="flex-1 p-3">
                <div className="md:max-w-xs pb-8">
                  <p className="mb-5 text-sm text-gray-400 font-semibold uppercase tracking-px">Step 1</p>
                  <h3 className="mb-2 text-xl text-white font-bold leading-normal">Choose A Package</h3>
                  <p className="text-gray-300 font-medium leading-relaxed">Lorem ipsum dolor sit amet, to the consectetur adipiscing elit.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="flex flex-wrap -m-3">
              <div className="w-auto p-3">
                <svg width={35} height={35} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="17.5" cy="17.5" r="17.5" fill="#4F46E5" />
                  <path d="M11.667 18.3333L15.0003 21.6666L23.3337 13.3333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <img className="mx-auto" src="flaro-assets/images/how-it-works/line3.svg" alt />
              </div>
              <div className="flex-1 p-3">
                <div className="md:max-w-xs pb-8">
                  <p className="mb-5 text-sm text-gray-400 font-semibold uppercase tracking-px">Step 2</p>
                  <h3 className="mb-2 text-xl text-white font-bold leading-normal">Pay with One Click</h3>
                  <p className="text-gray-300 font-medium leading-relaxed">Lorem ipsum dolor sit amet, to the consectetur adipiscing elit.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="flex flex-wrap -m-3">
              <div className="w-auto p-3">
                <svg width={35} height={35} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="17.5" cy="17.5" r={17} stroke="#CBD5E1" />
                  <path d="M11.667 18.3333L15.0003 21.6666L23.3337 13.3333" stroke="#94A3B8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <div className="flex-1 p-3">
                <div className="md:max-w-xs pb-8">
                  <p className="mb-5 text-sm text-gray-400 font-semibold uppercase tracking-px">Step 3</p>
                  <h3 className="mb-2 text-xl text-white font-bold leading-normal">Get Instant Access</h3>
                  <p className="text-gray-300 font-medium leading-relaxed">Lorem ipsum dolor sit amet, to the consectetur adipiscing elit.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

