import React from "react";
import P1 from "../../assets/images/p1.png";
import P2 from "../../assets/images/p11.png";
import P3 from "../../assets/images/p9.png";

import { Programs } from "../../data/Programs";
import { Link } from "react-router-dom";
export default function FeaturesSectionFeatures8() {
  return (
    <React.Fragment>
      <>
        <section className="pt-28 pb-36 bg-white overflow-hidden">
          <div className="container px-4 mx-auto">
            <div className="mb-20 flex flex-wrap -m-8">
              <div className="w-full  p-8">
                <h2 className="text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight  text-center">
                  نقدم العديد من البرامج التربوية والتكنولوجية والاجتماعية{" "}
                </h2>
              </div>
              {/*   <div className="w-full md:w-1/2 p-8">
                <p className="text-lg text-gray-900 font-medium leading-relaxed md:max-w-lg">
                  نقدم البرماج للمدارس الاعدايه والثانوية في المجالات التربوية
                  التكنلوجية والاجتماعية على حدا سواء
                </p>
              </div> */}
            </div>
            <div className="flex flex-wrap -m-3 mb-14">
              <div className="w-full md:w-1/3 p-3">
                <Link
                  to={`/program/${Programs[0].id}`}
                  relative="path"
                  params={{ id: Programs[0].id }}
                  className="flex justify-center md:block h-full"
                >
                  <div className="relative h-full rounded-3xl overflow-hidden">
                    <img
                      className="h-full md:w-full object-cover transform hover:scale-105 transition ease-in-out duration-1000"
                      src={Programs[0].Image}
                      alt
                    />
                    <div className="absolute bottom-0 left-0 w-full px-11 pb-10">
                      <div
                        className="px-4 py-4 bg-white bg-opacity-10 rounded-xl shadow-5xl"
                        style={{
                          WebkitBackdropFilter: "blur(5px)",
                          backdropFilter: "blur(5px)",
                        }}
                      >
                        <h3 className="text-md text-white text-center font-semibold">
                          {Programs[0].name}{" "}
                        </h3>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="w-full md:w-1/3 p-3">
                <Link
                  to={`/program/${Programs[10].id}`}
                  relative="path"
                  params={{ id: Programs[10].id }}
                  className="flex justify-center md:block h-full"
                >
                  <div className="relative h-full rounded-3xl overflow-hidden">
                    <img
                      className="h-full md:w-full object-cover transform hover:scale-105 transition ease-in-out duration-1000"
                      src={Programs[10].Image}
                      alt
                    />
                    <div className="absolute bottom-0 left-0 w-full px-11 pb-10">
                      <div
                        className="px-4 py-4 bg-white bg-opacity-10 rounded-xl shadow-5xl"
                        style={{
                          WebkitBackdropFilter: "blur(5px)",
                          backdropFilter: "blur(5px)",
                        }}
                      >
                        <h3 className="text-md text-white text-center font-semibold">
                          {Programs[10].name}{" "}
                        </h3>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="w-full md:w-1/3 p-3">
                <Link
                  to={`/program/${Programs[8].id}`}
                  relative="path"
                  params={{ id: Programs[8].id }}
                  className="flex justify-center md:block h-full"
                >
                  <div className="relative h-full rounded-3xl overflow-hidden">
                    <img
                      className="h-full md:w-full object-cover transform hover:scale-105 transition ease-in-out duration-1000 "
                      src={Programs[8].Image}
                      alt
                    />
                    <div className="absolute bottom-0 left-0 w-full px-11 pb-10">
                      <div
                        className="px-4 py-4 bg-white bg-opacity-10 rounded-xl shadow-5xl"
                        style={{
                          WebkitBackdropFilter: "blur(5px)",
                          backdropFilter: "blur(5px)",
                        }}
                      >
                        <h3 className="text-md text-white text-center font-semibold">
                          {Programs[8].name}{" "}
                        </h3>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="flex justify-center">
              <Link to="/programs">
                <button
                  className="py-4 px-6  w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200"
                  type="button"
                  style={{ maxWidth: 300 }}
                >
                  انتقل الى كل البرامج
                </button>
              </Link>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
