import React from 'react';

export default function BlodSectionBlog4() {
    return (
        <React.Fragment>
            <>
                <section className="pt-24 pb-36 bg-black overflow-hidden">
  <div className="container px-4 mx-auto">
    <h2 className="mb-6 text-6xl md:text-8xl xl:text-10xl text-white font-bold font-heading tracking-px-n leading-none">Our Latest Articles</h2>
    <p className="mb-20 text-lg text-gray-400 font-medium leading-normal md:max-w-xl">Lorem ipsum dolor sit amet, to the consectr adipiscing elit. Volutpat tempor to the condimentum vitae vel purus.</p>
    <div className="flex flex-wrap -m-8">
      <div className="w-full md:w-1/3 p-8">
        <div className="p-4 h-full bg-gray-900 bg-opacity-70 rounded-xl">
          <div className="flex flex-col justify-between h-full">
            <div className="mb-8">
              <div className="mb-9 w-full overflow-hidden rounded-2xl">
                <img className="w-full transform hover:scale-105 transition ease-in-out duration-1000" src="flaro-assets/images/blog/blog-wide3.png" alt />
              </div>
              <p className="mb-3 text-sm text-gray-400 font-medium">Product</p>
              <a className="inline-block text-white hover:text-gray-200 hover:underline" href="#">
                <h3 className="text-xl font-bold font-heading leading-normal">How startup company can help you to grow as a developer.</h3>
              </a>
            </div>
            <p className="text-sm text-gray-400 font-medium">12 May 2020</p>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/3 p-8">
        <div className="p-4 h-full bg-gray-900 bg-opacity-70 rounded-xl">
          <div className="flex flex-col justify-between h-full">
            <div className="mb-8">
              <div className="mb-9 w-full overflow-hidden rounded-2xl">
                <img className="w-full transform hover:scale-105 transition ease-in-out duration-1000" src="flaro-assets/images/blog/blog-wide3.png" alt />
              </div>
              <p className="mb-3 text-sm text-gray-400 font-medium">Product</p>
              <a className="inline-block text-white hover:text-gray-200 hover:underline" href="#">
                <h3 className="text-xl font-bold font-heading leading-normal">How startup company can help you to grow as a developer.</h3>
              </a>
            </div>
            <p className="text-sm text-gray-400 font-medium">12 May 2020</p>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/3 p-8">
        <div className="p-4 h-full bg-gray-900 bg-opacity-70 rounded-xl">
          <div className="flex flex-col justify-between h-full">
            <div className="mb-8">
              <div className="mb-9 w-full overflow-hidden rounded-2xl">
                <img className="w-full transform hover:scale-105 transition ease-in-out duration-1000" src="flaro-assets/images/blog/blog-wide3.png" alt />
              </div>
              <p className="mb-3 text-sm text-gray-400 font-medium">Product</p>
              <a className="inline-block text-white hover:text-gray-200 hover:underline" href="#">
                <h3 className="text-xl font-bold font-heading leading-normal">How startup company can help you to grow as a developer.</h3>
              </a>
            </div>
            <p className="text-sm text-gray-400 font-medium">12 May 2020</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

