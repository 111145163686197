import React from "react";
import Madinacollage from "../../assets/images/madinacollage.svg";
import Madinacenter from "../../assets/images/madinacenter.svg";
import Rian from "../../assets/images/rian.svg";

export default function FeaturesSectionFeatures11() {
  return (
    <React.Fragment>
      <>
        <section className="pt-24 pb-40 bg-white overflow-hidden">
          <div className="container px-4 mx-auto">
            <h2 className="mb-20 text-6xl md:text-8xl xl:text-10xl font-bold font-heading text-center tracking-px-n leading-none">
              مجموعة المدينه{" "}
            </h2>
            <div className="flex flex-wrap -m-16 md:-m-3">
              <div className="w-full md:w-1/3 p-16 md:p-3">
                <div className="px-10 pt-11 text-center bg-gray-100 h-96 rounded-4xl">
                  <h3 className="mb-3 text-xl font-bold font-heading leading-normal">
                    مركز المدينة{" "}
                  </h3>
                  <p className="mb-10 text-gray-600 font-medium leading-relaxed">
                    تتمحور حول التواصل الاجتماعي بين الطلبه ويكون سي والدكاء
                    الحسي الدي يمتلك المشتركون
                  </p>
                  <img
                    className="mx-auto h-72 object-fill rounded-3xl shadow-3xl transform hover:translate-y-3 transition ease-in-out duration-1000 bg-white p-4"
                  /*   src="https://static.shuffle.dev/uploads/files/42/428466a1a4f3491d243e451b656104d1d732c574/Frame-1-1.svg" */
                  src={Madinacenter}
                    alt
                    style={{maxHeight:250}}
                  />
                </div>
              </div>
              <div className="w-full md:w-1/3 p-16 md:p-3">
                <div className="px-10 pt-11 text-center bg-gray-100 h-96 rounded-4xl">
                  <h3 className="mb-3 text-xl font-bold font-heading leading-normal">
                    كلية المدينة
                  </h3>
                  <p className="mb-10 text-gray-600 font-medium leading-relaxed">
                    تتمحور هد حول قدرات الطلاب على حل المشاكل الهندسيه وملاحظة
                    امور ومزايا تبدو خفية عند التظرلى
                  </p>
                  <img
                    className="mx-auto h-72 object-fill   p-8 rounded-3xl shadow-3xl transform hover:translate-y-3 transition ease-in-out duration-1000 bg-white"
                 /*    src="https://www.almadina-college.com/media/main/%D7%9E%D7%9B%D7%9C%D7%9C%D7%94-%D7%A2%D7%A8%D7%91%D7%99%D7%AA.png" */
                 src={Madinacollage}
                    alt
                    style={{maxHeight:250}}
                  />
                </div>
              </div>
              <div className="w-full md:w-1/3 p-16 md:p-3" >
                <div className="px-10 pt-11 text-center bg-gray-100 h-96 rounded-4xl " >
                  <h3 className="mb-3 text-xl font-bold font-heading leading-normal">
                    البرامج التعاونية
                  </h3>
                  <p className="mb-10 text-gray-600 font-medium leading-relaxed">
                    تتمحور حول التواصل الاجتماعي بين الطلبه ويكون التركيز علىجاب
                    الحسي يمتلك المشتركون
                  </p>
                  <img
                    className="mx-auto h-72 object-contain p-3 rounded-3xl shadow-3xl transform hover:translate-y-3 transition ease-in-out duration-1000 bg-white"
                    /* src="https://www.almadina-college.com/media/main/%D9%85%D8%AD%D8%A7%D8%B3%D8%A8-%D8%A3%D8%AC%D9%88%D8%B1.jpg" */
                    src={Rian}
                    style={{maxHeight:250}}
                    alt
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
