import React from 'react';

export default function CallToAtcionSectionCta6() {
    return (
        <React.Fragment>
            <>
                <section className="relative pt-24 pb-28 bg-white overflow-hidden">
  <img className="absolute top-0 left-1/2 transform -translate-x-1/2" src="flaro-assets/images/cta/gradient4.svg" alt />
  <div className="relative z-10 container px-4 mx-auto">
    <div className="flex flex-wrap -m-8">
      <div className="w-full md:w-auto p-8">
        <a href="#">
          <img className="mx-auto transform hover:translate-y-4 transition ease-in-out duration-1000" src="flaro-assets/images/cta/man-play.png" alt />
        </a>
      </div>
      <div className="w-full md:flex-1 p-8">
        <div className="md:max-w-2xl mx-auto text-center">
          <h2 className="mb-10 text-6xl md:text-7xl font-bold font-heading text-center tracking-px-n leading-tight">Free for 14 days. Try everything, it’s on us</h2>
          <div className="mb-12 md:inline-block">
            <button className="py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Try 30 Days Free Trial</button>
          </div>
          <div className="md:max-w-sm mx-auto">
            <div className="flex flex-wrap -m-2">
              <div className="w-auto p-2">
                <svg className="mt-1" width={26} height={20} viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 20V14.2777C0 12.6321 0.306867 10.921 0.920601 9.14446C1.55293 7.34923 2.40844 5.65685 3.48712 4.06732C4.58441 2.45909 5.81187 1.10332 7.16953 0L11.8562 3.0575C10.7589 4.72183 9.83834 6.46096 9.09442 8.2749C8.3691 10.0701 8.01574 12.0524 8.03433 14.2216V20H0ZM14.1438 20V14.2777C14.1438 12.6321 14.4506 10.921 15.0644 9.14446C15.6967 7.34923 16.5522 5.65685 17.6309 4.06732C18.7282 2.45909 19.9557 1.10332 21.3133 0L26 3.0575C24.9027 4.72183 23.9821 6.46096 23.2382 8.2749C22.5129 10.0701 22.1595 12.0524 22.1781 14.2216V20H14.1438Z" fill="#E0E7FF" />
                </svg>
              </div>
              <div className="flex-1 p-2">
                <p className="mb-4 text-lg font-medium leading-normal text-left">Ease of use and efficiency of design tools. The ability for the team to see all of a project.</p>
                <h3 className="font-bold text-left">- Jenny Wilson</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-auto self-end p-8">
        <img className="mx-auto transform hover:-translate-y-4 transition ease-in-out duration-1000" src="flaro-assets/images/cta/woman-play2.png" alt />
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

