import React from "react";
import { Routes, Route } from "react-router-dom";

import IndexPage from "./pages/Index.js";
import FeaturesPage from "./pages/Features.js";
import HowItWorksPage from "./pages/How_it_works.js";
import CallToAtcionPage from "./pages/Call_to_atcion.js";
import LogosPage from "./pages/Logos.js";
import BlodPage from "./pages/Blod.js";
import ContactPage from "./pages/Contact.js";
import ProgramsPage from "./pages/Programs.js";
import ProgramPage from "./pages/Program.js";
import AboutPage from "./pages/About.js";

import ErrorPage from "./pages/Error.js";
import "./main_style.css";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.js";

function App() {
  return (
    <div>
      <Routes>
        
        <Route path="/" element={<IndexPage />} />

        <Route path="/features" element={<FeaturesPage />} />

        <Route path="/howitworks" element={<HowItWorksPage />} />

        <Route path="/calltoatcion" element={<CallToAtcionPage />} />

        <Route path="/logos" element={<LogosPage />} />
        <Route path="/programs" element={<ProgramsPage />} />
       {/*  <Route path="/program" element={<ProgramPage></ProgramPage>} /> */}
        <Route path="/program/:id" element={<ProgramPage />} />
        <Route path="/blod" element={<BlodPage />} />
        <Route path="/about" element={<AboutPage />} />

        <Route path="/contact" element={<ContactPage />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
      <ScrollToTop></ScrollToTop>
    </div>
  );
}

export default App;
