import React from 'react';

export default function LogosSectionLogos3() {
    return (
        <React.Fragment>
            <>
                <section className="pt-36 pb-28 bg-blueGray-100 overflow-hidden">
  <div className="container px-4 mx-auto">
    <div className="flex flex-wrap justify-center">
      <div className="w-auto">
        <img src="flaro-assets/logos/brands/logo-square-white.png" alt />
      </div>
      <div className="w-auto">
        <img src="flaro-assets/logos/brands/logo-square-white2.png" alt />
      </div>
      <div className="w-auto">
        <img src="flaro-assets/logos/brands/logo-square-white3.png" alt />
      </div>
      <div className="w-auto">
        <img src="flaro-assets/logos/brands/logo-square-white4.png" alt />
      </div>
      <div className="w-auto">
        <img src="flaro-assets/logos/brands/logo-square-white5.png" alt />
      </div>
      <div className="w-auto">
        <img src="flaro-assets/logos/brands/logo-square-white6.png" alt />
      </div>
    </div>
    <h2 className="mb-6 text-6xl md:text-8xl xl:text-10xl text-center font-bold font-heading tracking-px-n leading-none md:max-w-2xl mx-auto">Loved by the incredible community</h2>
    <p className="text-lg text-gray-600 text-center font-medium leading-normal md:max-w-lg mx-auto">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum.</p>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

