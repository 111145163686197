import React from 'react';

export default function CallToAtcionSectionCta8() {
    return (
        <React.Fragment>
            <>
                <section className="py-28 bg-white overflow-hidden">
  <div className="container px-4 mx-auto">
    <div className="mb-16 pb-20 border-b border-gray-200">
      <h2 className="text-6xl md:text-8xl xl:text-10xl font-bold font-heading tracking-px-n leading-none md:max-w-3xl">Build stunning websites in record time.</h2>
    </div>
    <div className="flex flex-wrap -m-8">
      <div className="w-full md:w-1/3 p-8">
        <div className="md:max-w-xs">
          <div className="flex flex-col justify-between h-full">
            <div className="mb-7">
              <h3 className="mb-7 text-2xl font-semibold leading-snug">Components</h3>
              <p className="text-gray-600 font-medium leading-relaxed">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit.</p>
            </div>
            <a className="inline-flex items-center max-w-max text-indigo-600 hover:text-indigo-700" href="#">
              <span className="mr-2 font-semibold">Explore Components</span>
              <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 3.75L15.75 9M15.75 9L10.5 14.25M15.75 9L2.25 9" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/3 p-8">
        <div className="md:max-w-xs">
          <div className="flex flex-col justify-between h-full">
            <div className="mb-7">
              <h3 className="mb-7 text-2xl font-semibold leading-snug">Landing Pages</h3>
              <p className="text-gray-600 font-medium leading-relaxed">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit.</p>
            </div>
            <a className="inline-flex items-center max-w-max text-indigo-600 hover:text-indigo-700" href="#">
              <span className="mr-2 font-semibold">Learn More</span>
              <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 3.75L15.75 9M15.75 9L10.5 14.25M15.75 9L2.25 9" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/3 p-8">
        <div className="md:max-w-xs">
          <div className="flex flex-col justify-between h-full">
            <div className="mb-7">
              <h3 className="mb-7 text-2xl font-semibold leading-snug">Free Figma File</h3>
              <p className="text-gray-600 font-medium leading-relaxed">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit.</p>
            </div>
            <a className="inline-flex items-center max-w-max text-indigo-600 hover:text-indigo-700" href="#">
              <span className="mr-2 font-semibold">Download Resource</span>
              <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 3.75L15.75 9M15.75 9L10.5 14.25M15.75 9L2.25 9" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

