import React from 'react';

export default function LogosSectionLogos2() {
    return (
        <React.Fragment>
            <>
                <section className="pt-24 pb-32 bg-white overflow-hidden">
  <div className="container px-4 mx-auto">
    <h2 className="mb-5 text-6xl md:text-7xl font-bold font-heading text-center tracking-px-n leading-tight">Companies love Flaro</h2>
    <p className="mb-20 text-lg text-gray-600 text-center font-medium leading-normal md:max-w-xl mx-auto">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus mi quis.</p>
    <div className="max-w-6xl mx-auto">
      <div className="flex flex-wrap items-center -m-6 lg:-m-14">
        <div className="w-full sm:w-1/2 lg:w-1/4 p-6 lg:p-14">
          <img className="mx-auto" src="flaro-assets/logos/brands/logo-xxl-light.png" alt />
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/4 p-6 lg:p-14">
          <img className="mx-auto" src="flaro-assets/logos/brands/logo-xxl-light2.png" alt />
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/4 p-6 lg:p-14">
          <img className="mx-auto" src="flaro-assets/logos/brands/logo-xxl-light3.png" alt />
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/4 p-6 lg:p-14">
          <img className="mx-auto" src="flaro-assets/logos/brands/logo-xxl-light4.png" alt />
        </div>
      </div>
    </div>
    <div className="mb-16 max-w-5xl mx-auto">
      <div className="flex flex-wrap items-center -m-6 lg:-m-11">
        <div className="w-full sm:w-1/2 lg:w-1/4 p-6 lg:p-11">
          <img className="mx-auto" src="flaro-assets/logos/brands/logo-xxl-light5.png" alt />
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/4 p-6 lg:p-11">
          <img className="mx-auto" src="flaro-assets/logos/brands/logo-xxl-light6.png" alt />
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/4 p-6 lg:p-11">
          <img className="mx-auto" src="flaro-assets/logos/brands/logo-xxl-light7.png" alt />
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/4 p-6 lg:p-11">
          <img className="mx-auto" src="flaro-assets/logos/brands/logo-xxl-light8.png" alt />
        </div>
      </div>
    </div>
    <div className="max-w-max mx-auto">
      <a className="inline-flex items-center text-indigo-600 hover:text-indigo-700" href="#">
        <span className="mr-2 font-semibold">See all 125 brands</span>
        <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.5 3.75L15.75 9M15.75 9L10.5 14.25M15.75 9L2.25 9" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </a>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

