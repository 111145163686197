import React from 'react';

export default function CallToAtcionSectionCta5() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-32 white overflow-hidden">
  <img className="hidden absolute bottom-0 right-0" src="flaro-assets/images/cta/gradient2.svg" alt />
  <div className="relative z-10 container px-4 mx-auto">
    <div className="flex flex-wrap lg:items-center -m-8">
      <div className="w-full md:w-1/2 p-8">
        <img className="mx-auto transform hover:translate-x-16 transition ease-in-out duration-1000" src="flaro-assets/images/cta/people.png" alt />
      </div>
      <div className="w-full md:w-1/2 p-8">
        <div className="relative md:max-w-xl">
          <img className="absolute top-0 right-0" src="flaro-assets/images/cta/gradient3.svg" alt />
          <div className="relative z-10">
            <h2 className="mb-11 text-6xl md:text-8xl xl:text-10xl font-bold font-heading tracking-px-n leading-none">Join &amp; build your startup successful</h2>
            <div className="md:max-w-lg">
              <div className="flex flex-wrap -m-2 mb-3">
                <div className="w-auto p-2">
                  <svg className="mt-1" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx={10} cy={10} r={10} fill="#4F46E5" />
                    <path d="M6 10.6667L8.66667 13.3333L14 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className="flex-1 p-2">
                  <p className="font-medium leading-relaxed">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat enim.</p>
                </div>
              </div>
              <div className="flex flex-wrap -m-2 mb-8">
                <div className="w-auto p-2">
                  <svg className="mt-1" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx={10} cy={10} r={10} fill="#4F46E5" />
                    <path d="M6 10.6667L8.66667 13.3333L14 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className="flex-1 p-2">
                  <p className="font-medium leading-relaxed">Mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat enim est sit arina.</p>
                </div>
              </div>
            </div>
            <div className="mb-4 md:max-w-md">
              <div className="p-1 xl:pl-7 inline-block md:max-w-xl w-full border-2 border-gray-200 rounded-3xl focus-within:ring focus-within:ring-indigo-300">
                <div className="flex flex-wrap items-center">
                  <div className="w-full xl:flex-1">
                    <input className="p-3 xl:p-0 xl:pr-7 w-full text-gray-600 bg-transparent placeholder-gray-600 outline-none" id="cta5-1" type="text" placeholder="Enter email to get started" />
                  </div>
                  <div className="w-full xl:w-auto">
                    <div className="block">
                      <button className="py-4 px-5 w-full text-sm text-white uppercase tracking-px font-bold rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Join for free</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap items-center">
              <div className="w-auto p-1">
                <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 11.25V12.75M4.5 15.75H13.5C14.3284 15.75 15 15.0784 15 14.25V9.75C15 8.92157 14.3284 8.25 13.5 8.25H4.5C3.67157 8.25 3 8.92157 3 9.75V14.25C3 15.0784 3.67157 15.75 4.5 15.75ZM12 8.25V5.25C12 3.59315 10.6569 2.25 9 2.25C7.34315 2.25 6 3.59315 6 5.25V8.25H12Z" stroke="#A1A1AA" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </div>
              <div className="flex-1 p-1">
                <p className="text-sm text-gray-500 font-medium">We don’t share or sell your email address publicly</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

