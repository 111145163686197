import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HowItWorksSectionHowItWorks1 from '../components/how-it-works/HowItWorksSectionHowItWorks1';
import HowItWorksSectionHowItWorks2 from '../components/how-it-works/HowItWorksSectionHowItWorks2';
import HowItWorksSectionHowItWorks3 from '../components/how-it-works/HowItWorksSectionHowItWorks3';
import HowItWorksSectionHowItWorks4 from '../components/how-it-works/HowItWorksSectionHowItWorks4';
import HowItWorksSectionHowItWorks5 from '../components/how-it-works/HowItWorksSectionHowItWorks5';
import HowItWorksSectionHowItWorks6 from '../components/how-it-works/HowItWorksSectionHowItWorks6';
import HowItWorksSectionHowItWorks7 from '../components/how-it-works/HowItWorksSectionHowItWorks7';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function HowItWorks() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <HowItWorksSectionHowItWorks1 />
      <HowItWorksSectionHowItWorks2 />
      <HowItWorksSectionHowItWorks3 />
      <HowItWorksSectionHowItWorks4 />
      <HowItWorksSectionHowItWorks5 />
      <HowItWorksSectionHowItWorks6 />
      <HowItWorksSectionHowItWorks7 />
    </React.Fragment>
  );
}

