import React from 'react';

export default function CallToAtcionSectionCta9() {
    return (
        <React.Fragment>
            <>
                <section className="py-28 bg-indigo-600 overflow-hidden">
  <div className="container px-4 mx-auto">
    <h2 className="mb-12 text-6xl md:text-8xl xl:text-10xl text-white text-center font-bold font-heading tracking-px-n leading-none md:max-w-4xl mx-auto">98% designers love Shuffle. Not convinced you’re one?</h2>
    <div className="mb-16 md:max-w-max mx-auto">
      <button className="py-4 px-6 w-full text-indigo-600 font-medium rounded-xl shadow-6xl focus:ring focus:ring-gray-300 bg-white hover:bg-gray-100 transition ease-in-out duration-200" type="button">Start 14 days free trial</button>
    </div>
    <p className="mb-9 text-blueGray-300 text-center font-medium leading-relaxed">Trusted by 100+ development team</p>
    <div className="mb-3 flex flex-wrap justify-center items-center max-w-4xl mx-auto -m-2">
      <div className="w-full sm:w-auto py-3 ms:py-0 sm:px-10">
        <img className="mx-auto" src="flaro-assets/logos/brands/brand-large.png" alt />
      </div>
      <div className="w-full sm:w-auto py-3 ms:py-0 sm:px-10">
        <img className="mx-auto" src="flaro-assets/logos/brands/brand-large2.png" alt />
      </div>
      <div className="w-full sm:w-auto py-3 ms:py-0 sm:px-10">
        <img className="mx-auto" src="flaro-assets/logos/brands/brand-large3.png" alt />
      </div>
    </div>
    <div className="flex flex-wrap justify-center items-center max-w-4xl mx-auto -m-2">
      <div className="w-full sm:w-auto py-3 ms:py-0 sm:px-10">
        <img className="mx-auto" src="flaro-assets/logos/brands/brand-large4.png" alt />
      </div>
      <div className="w-full sm:w-auto py-3 ms:py-0 sm:px-10">
        <img className="mx-auto" src="flaro-assets/logos/brands/brand-large5.png" alt />
      </div>
      <div className="w-full sm:w-auto py-3 ms:py-0 sm:px-10">
        <img className="mx-auto" src="flaro-assets/logos/brands/brand-large6.png" alt />
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

