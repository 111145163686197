import React from 'react';

export default function BlodSectionNumbers1() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-24 bg-white overflow-hidden">
  <img className="absolute bottom-0 left-0" src="flaro-assets/images/numbers/gradient.svg" alt />
  <div className="container px-4 mx-auto">
    <div className="flex flex-wrap -m-8">
      <div className="w-full md:w-1/2 lg:w-1/4 p-8">
        <h2 className="text-5xl font-bold font-heading tracking-px-n leading-tight">Flaro is the best UI Kit for SaaS Industry</h2>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/4 p-8">
        <div className="md:w-56">
          <h2 className="mb-3 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">15k+</h2>
          <p className="text-lg text-gray-700 font-medium leading-normal">Flaro has garnered more than 15k+ users.</p>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/4 p-8">
        <div className="md:w-56">
          <h2 className="mb-3 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">2.1M+</h2>
          <p className="text-lg text-gray-700 font-medium leading-normal">Flaro has garnered more than 15k+ users.</p>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/4 p-8">
        <div className="md:w-56">
          <h2 className="mb-3 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">93k</h2>
          <p className="text-lg text-gray-700 font-medium leading-normal">Flaro has garnered more than 15k+ users.</p>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

