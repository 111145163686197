import React from 'react';

export default function BlodSectionTeams7() {
    return (
        <React.Fragment>
            <>
                <section className="py-28 bg-white overflow-hidden">
  <div className="container px-4 mx-auto">
    <h2 className="mb-20 text-6xl md:text-8xl xl:text-10xl text-center font-bold font-heading tracking-px-n leading-none">The brain behind Flaro team</h2>
    <div className="max-w-5xl mx-auto">
      <div className="flex flex-wrap -m-3">
        <div className="w-full md:w-1/3 p-3">
          <div className="max-w-max mx-auto">
            <div className="mb-6 max-w-max overflow-hidden rounded-3xl">
              <img className="transform hover:scale-105 transition ease-in-out duration-1000" src="flaro-assets/images/team/bg-square.png" alt />
            </div>
            <h3 className="mb-1 text-lg font-bold font-heading leading-snug">Marvin McKinney</h3>
            <p className="font-medium text-gray-600">Co-Founder, Flaro</p>
          </div>
        </div>
        <div className="w-full md:w-1/3 p-3">
          <div className="max-w-max mx-auto">
            <div className="mb-6 max-w-max overflow-hidden rounded-3xl">
              <img className="transform hover:scale-105 transition ease-in-out duration-1000" src="flaro-assets/images/team/bg-square2.png" alt />
            </div>
            <h3 className="mb-1 text-lg font-bold font-heading leading-snug">Jacob Jones</h3>
            <p className="font-medium text-gray-600">Co-Founder, Flaro</p>
          </div>
        </div>
        <div className="w-full md:w-1/3 p-3">
          <div className="max-w-max mx-auto">
            <div className="mb-6 max-w-max overflow-hidden rounded-3xl">
              <img className="transform hover:scale-105 transition ease-in-out duration-1000" src="flaro-assets/images/team/bg-square3.png" alt />
            </div>
            <h3 className="mb-1 text-lg font-bold font-heading leading-snug">Ronald Richards</h3>
            <p className="font-medium text-gray-600">Co-Founder, Flaro</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

