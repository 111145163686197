import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import BlodSectionNumbers1 from '../components/numbers/BlodSectionNumbers1';
import BlodSectionNumbers2 from '../components/numbers/BlodSectionNumbers2';
import BlodSectionTestimonials3 from '../components/testimonials/BlodSectionTestimonials3';
import BlodSectionBlog4 from '../components/blog/BlodSectionBlog4';
import BlodSectionTeams5 from '../components/teams/BlodSectionTeams5';
import BlodSectionTeams6 from '../components/teams/BlodSectionTeams6';
import BlodSectionFaqs10 from '../components/faqs/BlodSectionFaqs10';
import BlodSectionNewsletter9 from '../components/newsletter/BlodSectionNewsletter9';
import BlodSectionFaqs8 from '../components/faqs/BlodSectionFaqs8';
import BlodSectionTeams7 from '../components/teams/BlodSectionTeams7';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Blod() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <BlodSectionNumbers1 />
      <BlodSectionNumbers2 />
      <BlodSectionTestimonials3 />
      <BlodSectionBlog4 />
      <BlodSectionTeams5 />
      <BlodSectionTeams6 />
      <BlodSectionFaqs10 />
      <BlodSectionNewsletter9 />
      <BlodSectionFaqs8 />
      <BlodSectionTeams7 />
    </React.Fragment>
  );
}

