import React from "react";

export default function FeaturesSectionFeatures9() {
  return (
    <React.Fragment>
      <>
        <section
          className="py-36 bg-white overflow-hidden "
         
        >
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap xl:items-center -m-8">
              <div className="w-full md:w-1/2 p-8">
                <img
                  className="transform hover:translate-x-16 transition ease-in-out duration-1000"
                  src="https://www.almadina-college.com/media/source/%D9%83%D9%84%D9%8A%D9%87-%D8%A7%D9%85-%D8%A7%D9%84%D9%81%D8%AD%D9%85.png"
                  alt
                />
              </div>
              <div className="w-full md:w-1/2 p-8">
                <div className="md:max-w-md">
                  <div className="flex flex-wrap -m-4">
                    <div className="w-auto p-4">
                      <div className="flex flex-wrap -m-2">
                        <div className="w-auto p-2">
                          <svg
                            width={28}
                            height={28}
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.66669 5.83329C4.66669 5.18896 5.18902 4.66663 5.83335 4.66663H22.1667C22.811 4.66663 23.3334 5.18896 23.3334 5.83329V8.16663C23.3334 8.81096 22.811 9.33329 22.1667 9.33329H5.83335C5.18902 9.33329 4.66669 8.81096 4.66669 8.16663V5.83329Z"
                              stroke="#6366F1"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M4.66669 15.1666C4.66669 14.5223 5.18902 14 5.83335 14H12.8334C13.4777 14 14 14.5223 14 15.1666V22.1666C14 22.811 13.4777 23.3333 12.8334 23.3333H5.83335C5.18902 23.3333 4.66669 22.811 4.66669 22.1666V15.1666Z"
                              stroke="#6366F1"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18.6667 15.1666C18.6667 14.5223 19.189 14 19.8334 14H22.1667C22.811 14 23.3334 14.5223 23.3334 15.1666V22.1666C23.3334 22.811 22.811 23.3333 22.1667 23.3333H19.8334C19.189 23.3333 18.6667 22.811 18.6667 22.1666V15.1666Z"
                              stroke="#6366F1"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="flex-1 p-2">
                          <h3 className="text-lg font-semibold">
                            طلاب وخريجي الكليات{" "}
                          </h3>
                        </div>
                        <div className="w-full p-2">
                          <div className="pb-8 border-b">
                            <p className="text-gray-600 font-medium leading-relaxed">
                              اليوم، يدرس حوالي 400 طالب في مجموعة متنوعة من
                              الدورات. وقد تخرج من الكلية حتى الآن أكثر من 2500
                              طالب: أكثر من 250 طالبًا في مجال الطاقة الشمسية،
                              وأكثر من 50 طالبًا في مجال تمديدات اللوحات
                              الكهربائية.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-auto p-4">
                      <div className="flex flex-wrap -m-2">
                        <div className="w-auto p-2">
                          <svg
                            width={28}
                            height={28}
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.83333 14.0001H22.1667M5.83333 14.0001C4.54467 14.0001 3.5 12.9554 3.5 11.6667V7.00008C3.5 5.71142 4.54467 4.66675 5.83333 4.66675H22.1667C23.4553 4.66675 24.5 5.71142 24.5 7.00008V11.6667C24.5 12.9554 23.4553 14.0001 22.1667 14.0001M5.83333 14.0001C4.54467 14.0001 3.5 15.0448 3.5 16.3334V21.0001C3.5 22.2887 4.54467 23.3334 5.83333 23.3334H22.1667C23.4553 23.3334 24.5 22.2887 24.5 21.0001V16.3334C24.5 15.0448 23.4553 14.0001 22.1667 14.0001M19.8333 9.33342H19.845M19.8333 18.6667H19.845"
                              stroke="#4F46E5"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="flex-1 p-2">
                          <h3 className="text-lg font-semibold">
                            المدينة المنورة في جميع أنحاء البلاد
                          </h3>
                        </div>
                        <div className="w-full p-2">
                          <div className="pb-8 border-b">
                            <p className="text-gray-600 font-medium leading-relaxed">
                              المدينة المنورة في جميع أنحاء البلاد تقوم كلية
                              المدينة بإجراء دورات في جميع أنحاء البلاد بدءاً من
                              منطقة المثلث (أم الفحم)، الجليل (تمارا)، النقب
                              والقدس.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-auto p-4">
                      <div className="flex flex-wrap -m-2">
                        <div className="w-auto p-2">
                          <svg
                            width={28}
                            height={28}
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.5 11.6666H24.5M8.16667 17.4999H9.33333M14 17.4999H15.1667M7 22.1666H21C22.933 22.1666 24.5 20.5996 24.5 18.6666V9.33325C24.5 7.40026 22.933 5.83325 21 5.83325H7C5.067 5.83325 3.5 7.40026 3.5 9.33325V18.6666C3.5 20.5996 5.067 22.1666 7 22.1666Z"
                              stroke="#4F46E5"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="flex-1 p-2">
                          <h3 className="text-lg font-semibold">
                            الاعتراف والتعاون
                          </h3>
                        </div>
                        <div className="w-full p-2">
                          <p className="text-gray-600 font-medium leading-relaxed">
                            كلية المدينة المنورة هي كلية معترف بها من قبل وزارة
                            الاقتصاد/ طمات، وهي في طور الاعتراف بها من قبل وزارة
                            التعليم (برنامج جيفين). هناك عدة دورات بالتعاون مع
                            "ريان" - مراكز التوظيف الفنار - لتعزيز العمالة
                            العربية في إسرائيل وماروم - الجمعية البلدية للترقية
                            بالإضافة إلى ذلك، كلية المدينة هي كلية عربية
                            بالتعاون مع بلدية أم الفحم وباقة الغربية و. الناصرة
                            في العديد من المشاريع وتعقد دورات في مواضيع مختلفة،
                            مثل: التكنولوجيا العالية والبيئة والبيئة الخضراء
                            والصحة والتمكين الشخصي والقيادة والتراث والثقافة.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
