import React from 'react';

export default function CallToAtcionSectionCta7() {
    return (
        <React.Fragment>
            <>
                <section className="py-36 white overflow-hidden">
  <div className="container px-4 mx-auto">
    <div className="flex flex-wrap -m-8">
      <div className="w-full md:w-1/2 xl:w-1/3 p-8">
        <div className="md:max-w-sm">
          <h2 className="mb-4 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">Build website fast with Flaro.</h2>
          <p className="text-gray-900 font-medium leading-relaxed">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
        </div>
      </div>
      <div className="w-full md:w-1/2 xl:w-2/3 p-8">
        <div className="flex flex-wrap justify-end -m-4">
          <div className="w-full xl:w-auto p-4">
            <div className="xl:max-w-sm h-full">
              <div className="px-9 py-8 h-full bg-blueGray-900 rounded-4xl shadow-7xl">
                <div className="flex flex-col justify-between h-full">
                  <div>
                    <svg className="mb-9" width={51} height={51} viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.6186 46.4724L36.6794 13.8038L4.01089 26.8647L11.6252 34.5008L30.1436 20.3397L15.9825 38.8581L23.6186 46.4724Z" fill="#E2E8F0" />
                    </svg>
                    <h3 className="mb-5 text-3xl text-white font-bold leading-snug">Free, Forever</h3>
                    <p className="mb-24 text-blueGray-300 font-medium">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit.</p>
                  </div>
                  <a className="inline-flex items-center max-w-max text-white hover:text-gray-200" href="#">
                    <span className="mr-2 font-sans font-medium">Join free for 30 days</span>
                    <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11 3.75L16.25 9M16.25 9L11 14.25M16.25 9L2.75 9" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full xl:w-auto p-4">
            <div className="xl:max-w-sm h-full">
              <div className="px-9 py-8 h-full bg-blueGray-900 rounded-4xl shadow-7xl">
                <div className="flex flex-col justify-between h-full">
                  <div>
                    <svg className="mb-14" width={33} height={33} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M29.7 0H3.3C1.47675 0 0.0165 1.47675 0.0165 3.3L0 33L6.6 26.4H29.7C31.5232 26.4 33 24.9233 33 23.1V3.3C33 1.47675 31.5232 0 29.7 0ZM11.55 14.85H8.25V11.55H11.55V14.85ZM18.15 14.85H14.85V11.55H18.15V14.85ZM24.75 14.85H21.45V11.55H24.75V14.85Z" fill="#E2E8F0" />
                    </svg>
                    <h3 className="mb-5 text-3xl text-white font-bold leading-snug">Chat with agent</h3>
                    <p className="mb-24 text-blueGray-300 font-medium">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit.</p>
                  </div>
                  <a className="inline-flex items-center max-w-max text-white hover:text-gray-200" href="#">
                    <span className="mr-2 font-sans font-medium">Start live chat</span>
                    <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11 3.75L16.25 9M16.25 9L11 14.25M16.25 9L2.75 9" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

