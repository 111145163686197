import React from 'react';

export default function FeaturesSectionFeatures1() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-32 bg-white overflow-hidden">
  <img className="absolute left-0 top-0" src="flaro-assets/images/features/elipse.svg" alt />
  <div className="container px-4 mx-auto">
    <div className="flex flex-wrap items-center -m-8">
      <div className="w-full md:w-1/2 p-8">
        <h2 className="mb-6 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">Build better customer experiences with Flaro</h2>
        <p className="font-sans text-lg text-gray-900 leading-relaxed md:max-w-lg">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.</p>
      </div>
      <div className="w-full md:w-1/2 p-8">
        <img className="transform hover:-translate-y-16 transition ease-in-out duration-1000" src="flaro-assets/images/features/feature.png" alt />
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

