import React from 'react';

export default function LogosSectionTestimonials4() {
    return (
        <React.Fragment>
            <>
                <section className="pt-24 pb-36 bg-blueGray-100 overflow-hidden">
  <div className="container px-4 mx-auto">
    <h2 className="mb-5 text-6xl md:text-7xl font-bold font-heading text-center tracking-px-n leading-tight">See what others are saying</h2>
    <p className="mb-16 text-lg text-gray-600 font-medium text-center mx-auto md:max-w-xl">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam.</p>
    <div className="flex flex-nowrap justify-center -m-1.5 mb-1.5">
      <div className="flex-shrink-0 max-w-xs p-1.5">
        <div className="p-6 h-full bg-white">
          <div className="flex flex-col justify-between h-full">
            <div className="pb-6">
              <div className="flex flex-wrap -m-0.5 mb-5">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
              </div>
              <p className="text-lg font-medium">Very very easy for customer information to get secured if all orders are on one device.</p>
            </div>
            <div>
              <div className="flex flex-wrap items-center -m-2">
                <div className="w-auto p-2">
                  <img src="flaro-assets/images/testimonials/avatar10.png" alt />
                </div>
                <div className="w-auto p-2">
                  <h3 className="text-base font-semibold">Eleanor Pena</h3>
                  <p className="text-gray-500">@brooklysim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 max-w-xs p-1.5">
        <div className="p-6 h-full bg-white">
          <div className="flex flex-col justify-between h-full">
            <div className="pb-6">
              <div className="flex flex-wrap -m-0.5 mb-5">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
              </div>
              <p className="text-lg font-medium">If you haven’t tried out Flaro App yet, I would definitely recommend it for both designers and developers!</p>
            </div>
            <div>
              <div className="flex flex-wrap items-center -m-2">
                <div className="w-auto p-2">
                  <img src="flaro-assets/images/testimonials/avatar7.png" alt />
                </div>
                <div className="w-auto p-2">
                  <h3 className="text-base font-semibold">Eleanor Pena</h3>
                  <p className="text-gray-500">@brooklysim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 max-w-xs p-1.5">
        <div className="p-6 h-full bg-white">
          <div className="flex flex-col justify-between h-full">
            <div className="pb-6">
              <div className="flex flex-wrap -m-0.5 mb-5">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
              </div>
              <p className="text-lg font-medium">Very very easy for customer information to get secured if all orders are on one device.</p>
            </div>
            <div>
              <div className="flex flex-wrap items-center -m-2">
                <div className="w-auto p-2">
                  <img src="flaro-assets/images/testimonials/avatar8.png" alt />
                </div>
                <div className="w-auto p-2">
                  <h3 className="text-base font-semibold">Eleanor Pena</h3>
                  <p className="text-gray-500">@brooklysim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 max-w-xs p-1.5">
        <div className="p-6 h-full bg-white">
          <div className="flex flex-col justify-between h-full">
            <div className="pb-6">
              <div className="flex flex-wrap -m-0.5 mb-5">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
              </div>
              <p className="text-lg font-medium">Ease of use and efficiency of design tools. The ability for the team to see all of a project.</p>
            </div>
            <div>
              <div className="flex flex-wrap items-center -m-2">
                <div className="w-auto p-2">
                  <img src="flaro-assets/images/testimonials/avatar9.png" alt />
                </div>
                <div className="w-auto p-2">
                  <h3 className="text-base font-semibold">Eleanor Pena</h3>
                  <p className="text-gray-500">@brooklysim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 max-w-xs p-1.5">
        <div className="p-6 h-full bg-white">
          <div className="flex flex-col justify-between h-full">
            <div className="pb-6">
              <div className="flex flex-wrap -m-0.5 mb-5">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
              </div>
              <p className="text-lg font-medium">Very very easy for customer information to get secured if all orders are on one device.</p>
            </div>
            <div>
              <div className="flex flex-wrap items-center -m-2">
                <div className="w-auto p-2">
                  <img src="flaro-assets/images/testimonials/avatar10.png" alt />
                </div>
                <div className="w-auto p-2">
                  <h3 className="text-base font-semibold">Eleanor Pena</h3>
                  <p className="text-gray-500">@brooklysim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 max-w-xs p-1.5">
        <div className="p-6 h-full bg-white">
          <div className="flex flex-col justify-between h-full">
            <div className="pb-6">
              <div className="flex flex-wrap -m-0.5 mb-5">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
              </div>
              <p className="text-lg font-medium">If you haven’t tried out Flaro App yet, I would definitely recommend it for both designers and developers!</p>
            </div>
            <div>
              <div className="flex flex-wrap items-center -m-2">
                <div className="w-auto p-2">
                  <img src="flaro-assets/images/testimonials/avatar4.png" alt />
                </div>
                <div className="w-auto p-2">
                  <h3 className="text-base font-semibold">Eleanor Pena</h3>
                  <p className="text-gray-500">@brooklysim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-nowrap justify-center -m-1.5">
      <div className="flex-shrink-0 max-w-xs p-1.5">
        <div className="p-6 h-full bg-white">
          <div className="flex flex-col justify-between h-full">
            <div className="pb-6">
              <div className="flex flex-wrap -m-0.5 mb-5">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
              </div>
              <p className="text-lg font-medium">Very very easy for customer information to get secured if all orders are on one device.</p>
            </div>
            <div>
              <div className="flex flex-wrap items-center -m-2">
                <div className="w-auto p-2">
                  <img src="flaro-assets/images/testimonials/avatar10.png" alt />
                </div>
                <div className="w-auto p-2">
                  <h3 className="text-base font-semibold">Eleanor Pena</h3>
                  <p className="text-gray-500">@brooklysim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 max-w-xs p-1.5">
        <div className="p-6 h-full bg-white">
          <div className="flex flex-col justify-between h-full">
            <div className="pb-6">
              <div className="flex flex-wrap -m-0.5 mb-5">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
              </div>
              <p className="text-lg font-medium">If you are thinking of a design partner to help you convert more customers, Flaro is a great choice.</p>
            </div>
            <div>
              <div className="flex flex-wrap items-center -m-2">
                <div className="w-auto p-2">
                  <img src="flaro-assets/images/testimonials/avatar11.png" alt />
                </div>
                <div className="w-auto p-2">
                  <h3 className="text-base font-semibold">Eleanor Pena</h3>
                  <p className="text-gray-500">@brooklysim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 max-w-xs p-1.5">
        <div className="p-6 h-full bg-white">
          <div className="flex flex-col justify-between h-full">
            <div className="pb-6">
              <div className="flex flex-wrap -m-0.5 mb-5">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
              </div>
              <p className="text-lg font-medium">Ease of use and efficiency of design tools. The ability for the team to see all of a project.</p>
            </div>
            <div>
              <div className="flex flex-wrap items-center -m-2">
                <div className="w-auto p-2">
                  <img src="flaro-assets/images/testimonials/avatar12.png" alt />
                </div>
                <div className="w-auto p-2">
                  <h3 className="text-base font-semibold">Eleanor Pena</h3>
                  <p className="text-gray-500">@brooklysim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 max-w-xs p-1.5">
        <div className="p-6 h-full bg-white">
          <div className="flex flex-col justify-between h-full">
            <div className="pb-6">
              <div className="flex flex-wrap -m-0.5 mb-5">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
              </div>
              <p className="text-lg font-medium">If you haven’t tried out Flaro App yet, I would definitely recommend it for both designers and developers!</p>
            </div>
            <div>
              <div className="flex flex-wrap items-center -m-2">
                <div className="w-auto p-2">
                  <img src="flaro-assets/images/testimonials/avatar13.png" alt />
                </div>
                <div className="w-auto p-2">
                  <h3 className="text-base font-semibold">Eleanor Pena</h3>
                  <p className="text-gray-500">@brooklysim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 max-w-xs p-1.5">
        <div className="p-6 h-full bg-white">
          <div className="flex flex-col justify-between h-full">
            <div className="pb-6">
              <div className="flex flex-wrap -m-0.5 mb-5">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
              </div>
              <p className="text-lg font-medium">It was very easy for customer information to get secured if all orders are on one device.</p>
            </div>
            <div>
              <div className="flex flex-wrap items-center -m-2">
                <div className="w-auto p-2">
                  <img src="flaro-assets/images/testimonials/avatar14.png" alt />
                </div>
                <div className="w-auto p-2">
                  <h3 className="text-base font-semibold">Eleanor Pena</h3>
                  <p className="text-gray-500">@brooklysim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 max-w-xs p-1.5">
        <div className="p-6 h-full bg-white">
          <div className="flex flex-col justify-between h-full">
            <div className="pb-6">
              <div className="flex flex-wrap -m-0.5 mb-5">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
              </div>
              <p className="text-lg font-medium">If you are thinking of a design partner to help you convert more customers, Flaro is a great choice.</p>
            </div>
            <div>
              <div className="flex flex-wrap items-center -m-2">
                <div className="w-auto p-2">
                  <img src="flaro-assets/images/testimonials/avatar15.png" alt />
                </div>
                <div className="w-auto p-2">
                  <h3 className="text-base font-semibold">Eleanor Pena</h3>
                  <p className="text-gray-500">@brooklysim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 max-w-xs p-1.5">
        <div className="p-6 h-full bg-white">
          <div className="flex flex-col justify-between h-full">
            <div className="pb-6">
              <div className="flex flex-wrap -m-0.5 mb-5">
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
                <div className="w-auto p-0.5">
                  <svg width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30769 0L12.1838 5.82662L18.6154 6.76111L13.9615 11.2977L15.0598 17.7032L9.30769 14.6801L3.55554 17.7032L4.65385 11.2977L0 6.76111L6.43162 5.82662L9.30769 0Z" fill="#F59E0B" />
                  </svg>
                </div>
              </div>
              <p className="text-lg font-medium">If you haven’t tried out Flaro App yet, I would definitely recommend it for both designers and developers!</p>
            </div>
            <div>
              <div className="flex flex-wrap items-center -m-2">
                <div className="w-auto p-2">
                  <img src="flaro-assets/images/testimonials/avatar4.png" alt />
                </div>
                <div className="w-auto p-2">
                  <h3 className="text-base font-semibold">Eleanor Pena</h3>
                  <p className="text-gray-500">@brooklysim</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

