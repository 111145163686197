import React from 'react';

export default function HowItWorksSectionHowItWorks6() {
    return (
        <React.Fragment>
            <>
                <section className="pt-24 pb-32 bg-white overflow-hidden">
  <div className="container px-4 mx-auto">
    <div className="flex flex-wrap -m-8">
      <div className="w-full md:w-1/2 p-8">
        <div className="md:max-w-lg">
          <h2 className="mb-14 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">Three easy steps</h2>
          <div className="flex flex-wrap -m-5 mb-9">
            <div className="w-full p-5">
              <div className="flex flex-wrap -m-4">
                <div className="w-auto p-4">
                  <div className="relative mb-3 w-16 h-16 text-lg text-indigo-600 font-bold border border-gray-200 rounded-full">
                    <img className="absolute top-0 left-0" src="flaro-assets/images/how-it-works/gradient.svg" alt />
                    <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">1</span>
                  </div>
                </div>
                <div className="flex-1 p-4">
                  <div className="md:max-w-xs">
                    <h3 className="mb-3 text-xl font-semibold leading-normal">Connect your data</h3>
                    <p className="text-gray-600 font-medium leading-relaxed">Lorem ipsum dolor amet adipiscing or elit. Volutpat tempor mentum.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full p-5">
              <div className="flex flex-wrap -m-4">
                <div className="w-auto p-4">
                  <div className="relative mb-3 w-16 h-16 text-lg text-indigo-600 font-bold border border-gray-200 rounded-full">
                    <img className="absolute top-0 left-0" src="flaro-assets/images/how-it-works/gradient.svg" alt />
                    <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">2</span>
                  </div>
                </div>
                <div className="flex-1 p-4">
                  <div className="md:max-w-xs">
                    <h3 className="mb-3 text-xl font-semibold leading-normal">Share with a link</h3>
                    <p className="text-gray-600 font-medium leading-relaxed">Lorem ipsum dolor amet adipiscing Volutpat tempor with anyone.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full p-5">
              <div className="flex flex-wrap -m-4">
                <div className="w-auto p-4">
                  <div className="relative mb-3 w-16 h-16 text-lg text-indigo-600 font-bold border border-gray-200 rounded-full">
                    <img className="absolute top-0 left-0" src="flaro-assets/images/how-it-works/gradient.svg" alt />
                    <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">3</span>
                  </div>
                </div>
                <div className="flex-1 p-4">
                  <div className="md:max-w-xs">
                    <h3 className="mb-3 text-xl font-semibold leading-normal">Connect your data</h3>
                    <p className="text-gray-600 font-medium leading-relaxed">Lorem ipsum dolor amet adipiscing or elit. Volutpat tempor mentum.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:inline-block">
            <button className="py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Join Free for 30 Days</button>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 p-8">
        <img className="mx-auto transform hover:translate-y-4 transition ease-in-out duration-1000" src="flaro-assets/images/how-it-works/steps.png" alt />
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

