import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CallToAtcionSectionCta1 from '../components/cta/CallToAtcionSectionCta1';
import CallToAtcionSectionCta2 from '../components/cta/CallToAtcionSectionCta2';
import CallToAtcionSectionCta3 from '../components/cta/CallToAtcionSectionCta3';
import CallToAtcionSectionCta4 from '../components/cta/CallToAtcionSectionCta4';
import CallToAtcionSectionCta6 from '../components/cta/CallToAtcionSectionCta6';
import CallToAtcionSectionCta5 from '../components/cta/CallToAtcionSectionCta5';
import CallToAtcionSectionCta7 from '../components/cta/CallToAtcionSectionCta7';
import CallToAtcionSectionCta9 from '../components/cta/CallToAtcionSectionCta9';
import CallToAtcionSectionCta8 from '../components/cta/CallToAtcionSectionCta8';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function CallToAtcion() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <CallToAtcionSectionCta1 />
      <CallToAtcionSectionCta2 />
      <CallToAtcionSectionCta3 />
      <CallToAtcionSectionCta4 />
      <CallToAtcionSectionCta6 />
      <CallToAtcionSectionCta5 />
      <CallToAtcionSectionCta7 />
      <CallToAtcionSectionCta9 />
      <CallToAtcionSectionCta8 />
    </React.Fragment>
  );
}

