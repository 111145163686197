import React from "react";
import IMgPeople from "../../assets/images/aaaa.jpg";

export default function IndexSectionContact11() {
  const toast = (str, extraClass = "", timeout = 3000) => {
    const div = document.createElement("div");
    div.className = "toast " + extraClass;
    div.innerHTML = str;
    setTimeout(function () {
      div.remove();
    }, timeout);
    document.body.appendChild(div);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "62e53c52-8437-41ac-8129-85788bc22585");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      console.log("Success", res);
      toast(" تم ارسال الرسالة بنجاح", "success", 5000);
    } else {
      console.log("error", res);
      toast(" تعذر ارسال الرسالة ", "error", 5000);
    }
  };
  return (
    <React.Fragment>
      <>
        <section
          className="relative pt-28 pb-36 bg-blueGray-100 overflow-hidden"
          style={{ paddingTop: 200 }}
        >
          <div className="relative z-10 container px-4 mx-auto">
            <p className="mb-6 text-5xl text-indigo-600 text-center font-semibold uppercase tracking-px">
              اتصل بنا
            </p>
            <h2 className="mb-14 text-6xl md:text-7xl text-center font-bold font-heading tracking-px-n leading-tight md:max-w-xl mx-auto">
              هل لديك بعض الأسئلة؟ دعونا نتواصل
            </h2>
            <form
              className="px-11 pt-9 pb-11 bg-white bg-opacity-80 md:max-w-xl mx-auto rounded-4xl shadow-12xl"
              style={{ backdropFilter: "blur(5px)" }}
              onSubmit={onSubmit}
            >
              <h3 className="mb-8 text-xl text-center font-semibold leading-normal md:max-w-sm mx-auto">
                أرسل لنا رسالة لمعرفة المزيد عنا أو قم فقط بالدردشة.
              </h3>
              <label className="block mb-4">
                <p className="mb-2 text-gray-900 font-semibold leading-normal">
                  الاسم
                </p>
                <div className="relative">
                  <svg
                    className="absolute left-4 top-1/2 transform -translate-y-1/2"
                    width={20}
                    height={26}
                    viewBox="0 0 20 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.3327 8.83358C13.3327 10.6745 11.8403 12.1669 9.99935 12.1669C8.1584 12.1669 6.66602 10.6745 6.66602 8.83358C6.66602 6.99263 8.1584 5.50024 9.99935 5.50024C11.8403 5.50024 13.3327 6.99263 13.3327 8.83358Z"
                      stroke="#9CA3AF"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.99935 14.6669C6.77769 14.6669 4.16602 17.2786 4.16602 20.5002H15.8327C15.8327 17.2786 13.221 14.6669 9.99935 14.6669Z"
                      stroke="#9CA3AF"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <input
                    className="pl-12 pr-4 py-3 w-full text-gray-500 font-medium placeholder-gray-500 outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300"
                    id="contactInput3-1"
                    type="text"
                    name="name"
                    placeholder="الاسم"
                  />
                </div>
              </label>

              <label className="block mb-4">
                <p className="mb-2 text-gray-900 font-semibold leading-normal">
                  البريد الالكتروني
                </p>
                <div className="relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    width={20}
                    height={26}
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    className="absolute left-4 top-1/2 transform -translate-y-1/2"
                    stroke="#9CA3AF"
                    strokeWidth="1.5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                    />
                  </svg>

                  <input
                    className="pl-12 pr-4 py-3 w-full text-gray-500 font-medium placeholder-gray-500 outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300"
                    id="contactInput3-2"
                    type="text"
                    name="email"
                    placeholder="البريد الالكتروني"
                  />
                </div>
              </label>

              <label className="block">
                <p className="mb-2 text-gray-900 font-semibold leading-normal">
                  الرسالة
                </p>
                <div className="relative">
                  <svg
                    className="absolute left-4 top-4"
                    width={20}
                    height={26}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke="#9CA3AF"
                      strokeWidth="1.5"
                      d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z"
                    />
                  </svg>

                  <textarea
                    className="mb-6 p-4 px-12 w-full h-48 font-medium text-gray-500 outline-none placeholder-gray-500 border border-gray-300 resize-none rounded-lg focus:ring focus:ring-indigo-300"
                    id="contactInput3-3"
                    name="message"
                    placeholder="اكتب هنا ..."
                    defaultValue={""}
                  />
                </div>
              </label>

              <button
                className="py-4 px-9 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200"
                type="submit"
              >
                أرسل رسالة
              </button>
            </form>
          </div>
          <img
            className="absolute bottom-0 w-full object-cover transform hover:translate-y-4 transition ease-in-out duration-1000"
            style={{ height: 700 }}
            src={IMgPeople}
            alt
          />
        </section>
      </>
    </React.Fragment>
  );
}
