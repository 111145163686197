import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import LogosSectionLogos1 from '../components/logos/LogosSectionLogos1';
import LogosSectionLogos2 from '../components/logos/LogosSectionLogos2';
import LogosSectionLogos3 from '../components/logos/LogosSectionLogos3';
import LogosSectionTestimonials4 from '../components/testimonials/LogosSectionTestimonials4';
import LogosSectionTestimonials5 from '../components/testimonials/LogosSectionTestimonials5';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Logos() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <LogosSectionLogos1 />
      <LogosSectionLogos2 />
      <LogosSectionLogos3 />
      <LogosSectionTestimonials4 />
      <LogosSectionTestimonials5 />
    </React.Fragment>
  );
}

