import React from "react";

export default function BlodSectionNumbers2() {
  return (
    <React.Fragment>
      <>
        <section className="py-24 bg-blueGray-100 overflow-hidden">
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap max-w-5xl mx-auto -m-3">
              <div className="md:w-1/2 lg:w-1/4 sm:w-full w-full p-3">
                <div className="py-8 px-12 h-full text-center bg-white rounded-xl">
                  <h2 className="mb-3 text-6xl md:text-8xl xl:text-10xl text-indigo-600 text-center font-bold font-heading tracking-px-n leading-none">
                    20+{" "}
                  </h2>
                  <p className="text-gray-700 font-medium leading-relaxed">
                    اكثر من عشرين سنة خبرة في مجال التعليم{" "}
                  </p>
                </div>
              </div>
              <div className=" md:w-1/2 lg:w-1/4 sm:w-full w-full p-3">
                <div className="py-8 px-12 h-full text-center bg-white rounded-xl">
                  <h2 className="mb-3 text-6xl md:text-8xl xl:text-10xl text-indigo-600 text-center font-bold font-heading tracking-px-n leading-none">
                    21
                  </h2>
                  <p className="text-gray-700 font-medium leading-relaxed">
                    نوفر اكثر من عشرين برنامجا في גפ"ן
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 lg:w-1/4 sm:w-full w-full p-3" >
                <div className="py-8 px-12 h-full text-center bg-white rounded-xl">
                  <h2 className="mb-3 text-6xl md:text-8xl xl:text-10xl text-indigo-600 text-center font-bold font-heading tracking-px-n leading-none">
                    2021
                  </h2>
                  <p className="text-gray-700 font-medium leading-relaxed">
                    نقدم خدماتنا في גפ"ן منذ سنة 2021{" "}
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 lg:w-1/4 sm:w-full w-full p-3">
                <div className="py-8 px-12 h-full text-center bg-white rounded-xl">
                  <h2 className="mb-3 text-6xl md:text-8xl xl:text-10xl text-indigo-600 text-center font-bold font-heading tracking-px-n leading-none">
                    39
                  </h2>
                  <p className="text-gray-700 font-medium leading-relaxed">
                   طاقم ارشادي مكون من 39 مرشدا ومختصنا في مجالاتهم
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
