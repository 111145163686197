import P1 from "../assets/images/p1.png";
import P2 from "../assets/images/p2.png";
import P3 from "../assets/images/p3.png";
import P4 from "../assets/images/p4.png";
import P5 from "../assets/images/p5.png";
import P6 from "../assets/images/p6.png";
import P7 from "../assets/images/p7.png";
import P8 from "../assets/images/p8.png";
import P9 from "../assets/images/p9.png";
import P10 from "../assets/images/p10.png";
import P11 from "../assets/images/p11.png";
import P12 from "../assets/images/p12.png";
import P13 from "../assets/images/p13.png";
import P14 from "../assets/images/p14.png";

export const Programs = [
  {
    id: "20852",
    name: "‫רובוטיקה ו ‪ 10-‬המיומניות‬",
    desc: `על מנת להכין בוגר שיכול להתמודד‬
‫עם אתגרי העידן החדש אנחנו‬
‫מאמינים שיש להעצים את מיומנויות‬
‫המאה ‪ ,21‬אנו רואים ברובוטיקה כלי‬
‫מאוד חזק להשגת מטרה זו‪.‬‬
‫בניית רובוטים המשרתים מטרה‬
‫ופותרים בעיה בתחום החיים מפתחת‬
‫אצל הילד מיומנות חקר בעיות‪,‬‬
‫לימוד עצמי‪ ,‬מציאת פתרונות‬
‫יצירתיים‪ ,‬עבודה בצוות‪ ,‬חשיבה‬
‫לוגית‪ ,‬חשיבה מסדר גבוהה‪,‬‬
‫חשיבה ביקורתית‪ ,‬עמידה מול קהל‬
‫ורכישת שפת‬
‫להצגת המוצר‪,‬‬
‫תכנות‪.‬‬
`,
    audience: [
      `‫גן חובה עד כיתות י"ב‬`,
      `‫תלמידים מצטיינים‬`,
      `‫תלמידי חינוך מיוחד‬`,
    ],
    tech: true,
    Image: P1,
  },
  {
    id: "20858",
    name: "‫רחפנים כל אחד יכול‬",
    desc: `רחפנים היום הם כלי הטיס הנחקרים‬
‫ביותר במעבדות המובילות בעולם‬
‫העוסקות במחקר ופיתוח בתחומים‬
‫שוני‪ ,‬משלוחים‪ ,‬צילום ולמטרות‬
‫ביטחוניות ‪.‬‬
‫המשתתפים בתוכנית ילמדו בצוותים‬
‫קטנים ‪,‬הלמידה תהיה למידה‬
‫באמצעות פרויקטים במהלך התוכנית‬
‫המשתתפים יחשפו למגוון של תכנים‬
‫‪:‬שפה אנגלית ‪ ,‬הנדסה‪ ,‬מחשבים‪,‬‬
‫תעופה‪,‬רובוטיקה‬
‫אלקטרוניקה‪,‬‬
‫ואווירונאוטיקה‪ .‬המשתתפים יתנסו‬
‫בפתרון בעיות מורכבות ‪,‬יחשפו‬
‫לאתגרים חדשים ויחוו למידה‬
‫משמעותית המשלבת רכישת ידע‬
‫חדש ויישומו ‪,‬העצמת תחושת‬
‫המסוגלות העצמית של המשתתפים‪,‬‬
‫ירכשו מיומנויות חקר עם לא מעט‬
‫הנאה‪.‬‬
‫המשתתפים ילמדו להרכיב‪ ,‬לתכנת‬
‫ולהטיס רחפנים‪.‬‬
`,
    audience: [`‫תלמידי כיתות א' עד י"ב‬`],
    tech: true,
    Image: P2,
  },
  {
    id: "20924",
    name: `מנהיגות צעירה מקדמת‬
‫גישור ופתרון קונפליקטים
`,
    desc: `גישור הינו תהליך לפתרון מחלוקות‬
‫בין אנשים‪ .‬הייחודי בגישור וסוד כוחו‬
‫לעומת הליכים אחרים ‪ ,‬הוא שהפתרון‬
‫אליו מגיעים בסיום התהליך הוא‬
‫פתרון המוסכם על כל הצדדים‬
‫שמושג בהסכמה של הצדדים ולא‬
‫בכפייה‪.‬‬
‫בתוכנית תהיה שימת דגש רב על‬
‫ערכים ‪,‬חיזוק המיומנות הרכות בקרב‬
‫המשתתפים ותפתח הזהות החברתית‬
‫שלהם לשילובם בקהילה ובחברה ‪.‬‬
‫בתוכנית תהיה חשיפה נרחבת למגוון‬
‫כלים זמינים לטובת עיצוב דור‬
‫שמאמין בסובלנות ובהידברות כדרך‬
‫חיים ‪.‬‬
`,
    audience: [`‫בני נוער בגילאים ‪12-18‬‬`, `הורים`, `‫צוותי הוראה‬`],
    tech: false,
    Image: P3,
  },
  {
    id: "21096",
    name: `העולם מקרוב ( מציאות מדומה VR ואנטלגנציה מלאכותית AI)`,
    desc: `התכנית בנויה כך שתספק חוויה‬
‫לימודית מיוחדת המושכת תשומת לב‬
‫התלמידים ותוביל למהפכה בעולם‬
‫ההוראה‪ .‬התוכנית מתאימה לכל‬
‫הגילאים ולכל הרמות ואף חובה‬
‫לתלמידים מתקשים ותלמידים של‬
‫החינוך המיוחד‪ .‬לתכנית מצורף‬
‫סילבוס מובנה לפי נושאים הנבחרים‬
‫בקפדנות ומתואמים לקהל היעד‪ ,‬ניתן‬
‫להוסיף תכנים לפי הדרישות של בתי‬
‫הספר או המוסדות ‪.‬כל הנושאים‬
‫בתוכנית נתמכים ע"י טכנולוגיית‬
‫המציאות המדומה ומותאמים לפי‬
‫תכנית הלימודים ולכל שכבת גיל‪.‬‬
‫התוכנית מעודדת למידה עצמית‬
‫‪,‬משמעותית ‪,‬פעילה וללא הפרעות‬
‫ורעשים תוך שליטה מלאה של המדריך‬
‫או המורה על הנעשה בכיתה ‪.‬‬
`,
    audience: [
      `‫גנים ‪ :‬החל מ‪4 -‬שנים‬`,

      `‫בתי ספר‪ :‬א'‪-‬י"ב‬`,
      `‫צוות חינוכי‬`,
    ],
    tech: true,
    Image: P4,
  },
  {
    id: "21228",
    name: `‫טיקטוק בטיק טאק‬`,
    desc: `אנו נמצאים בעידן בו כל יום נוצרים‬
‫מיליונים על מיליונים של תכנים במערכות‬
‫תקשורת ומידע שונות ומשונות ‪,‬רשתות‬
‫חברתיות הפכו להיות חלק בלתי נפרד‬
‫מחיינו‪ .‬אנשים בכל הגילאים מחוברים‬
‫ומקשורים ברשתות חברתיות שונות‬
‫‪,‬שגוזלות המון מזמנם היומיומי וגם‬
‫מכספם ‪.‬‬
‫פעמים רבות אנו שומעים את המונחים‬
‫"התוכן הוא המלך" ו "התוכן " הוא הדבר‬
‫החשוב ביותר בשיווק של אנשים ‪ ,‬חברות‬
‫ארגונים‪ ,‬אבל למעשה אף אחד לא עוצר‬
‫ומסביר מה זה תוכן טוב ואיך עושים תוכן‬
‫טוב למערכות דיגיטליות ‪.‬‬
‫התוכית תחשוף את המשתתפים לעולם‬
‫המושגים של עולם התקשורת המשתנה ‪,‬‬
‫תטפח ותקדם את היכולת להתמצא‬
‫במרחב המקוון‪ ,‬להכיר את מאפייניו‪,‬‬
‫להעריך את האיכות והאמינות של מסרים‬
‫עתירי צורות ייצוג מתוך זיהוי מאפייני‬
‫המידע‪ .‬המשתתפים יתנסו ביצירת תוכן‬
‫מולטימדיה באמצעות כלים דיגיטליים‬
‫מגוונים וקידום תקשורת בין‪-‬אישית‬
‫וקבוצתית באופן יעיל ומותאם לצרכים‪.‬‬
`,
    audience: [`‫בני נוער בגילאי כיתות (ז' –י"ב)‪.‬‬`],
    tech: true,
    Image: P5,
  },
  {
    id: "21312",
    name: `ליווי מנהלים ובתי ספר‬
‫ושיפור הישגים בעידן הגפ"ן‬
`,
    desc: `המטרת תוכנית זו‪ ,‬ללוות ולסייע למנהלים‬
‫בבניית תוכנית עבודה מקושרת משאבים‬
‫ומכוונת תוצאות – בראייה תלת שנתית!!‬
‫דגשי הליווי‪ :‬שילוב תוכנית עבודה לשיפור‬
‫הישגים ושיפור אח"מ‪.‬‬
‫סיוע וליווי בהיבטים החשבונאיים של‬
‫הטיפול בתקציב גפ"ן‪.‬‬
‫ניתן להפעיל את התוכנית בתהליך מקוצר‬
‫או כתוכנית ללוי שנתי‪.‬‬
`,
    audience: [
      `‫מנהלי בתי ספר‬`,
      `‫צוותי הוראה מובילים‬`,
      `‫מנהלי מחלקות חינוך‬`,
    ],
    tech: false,
    Image: P6,
  },
  {
    id: "22520",
    name: `העשרה בערוץ החלומות‬
‫(חוגי העשרה)‬
`,
    desc: `חוגי העשרה פותחים בפניו של הילד או‬
‫הנער עולם מגוון וססגוני אשר בו‬
‫מתאפשרת הבחירה לפי התחביבים‬
‫ותחומי ההתעניינות של הנער דבר שאין‬
‫ביכולתו לעשות בתוך כותלי בית הספר ‪.‬‬
‫חוגי העשרה מסייעים לילדים ובני נוער‬
‫לנצל את שעות הפנאי אחרי יום הלימודים‬
‫ובחופשות ומביאים להעלאת המוטיבציה‬
‫עקב‬
‫המסוגלות‬
‫ותחושת‬
‫האישית‬
‫האפשרות של הבחירה החופשית לפי‬
‫תחביב או תחום עניין ‪.‬‬
‫מדעים והנדסה‬
‫מדען צעיר‪/‬חשבון וחשיבה כמותית‪/‬‬
‫רובוטיקה‪/‬דרונז(רחפנים)‪ /‬אווירונאוטיקה‬
‫וחלל ‪ /‬מדע בעלי חיים ‪ /‬המהנדס הצעיר ‪/‬‬
‫מתמטיקה מדעית ‪ /‬מדע וקיימות‪.‬‬
‫אומנות‬
‫צילום‪ /‬אומנות הציור על קירות ציבוריים‬
‫‪/‬תיאטרון בובות ‪ /‬להקת שירה ‪ /‬זומבה‬
‫עם‪/‬‬
‫וריקודי‬
‫ותנועה‪/‬ריקוד‬
‫‪/‬צליל‬
‫פלסטלינה ומוטוריקה ‪ /‬שירה ומנגינה ‪/‬‬
‫פיסול בחימר ופסיפס‬
`,
    audience: [`‫ג'‪-‬ו'‬`, `‫בחירה ‪ :‬לפי תחומי עניין של התלמיד‬`],
    tech: false,
    Image: P7,
  },
  {
    id: "22589",
    name: `עוברים בירוק‬
‫(חינוך תעבורתי מגיל צעיר)‬
`,
    desc: `מידי יום אנו שומעים על אסון חדש או יותר‬
‫כתוצאה מתאונות הדרכים‪ ,‬לתאונות‬
‫הדרכים סיבות רבות כמו בעיית התשתיות‪,‬‬
‫לקויות ברכב עצמו‪ ,‬והתנהלות האנושית‬
‫הכתבות‬
‫הסקרים‬
‫לפי‬
‫בכבישים‪,‬‬
‫והמאמרים המעידים שהאחוז הגדול מבין‬
‫הגורמים שהוזכרו הוא באחריות הגורם‬
‫האנושי‪.‬‬
‫בני הנוערהם אחד ממשתמשי הדרך‪,‬‬
‫חלקם הולכי רגל‪ ,‬רוכבי אופניים‪ ,‬אופניים‬
‫חשמליים וקורקינט‪ ,‬והם הופכים להיות‬
‫נהגים בעתיד הקרוב‪ ,‬העלאת המודעות‬
‫ולמידת כללי ההתנהגות הנכונה בגיל קטן‬
‫תביא לתוצאות טובות יותר‪.‬‬
‫התכנית תפעל בכמה מישורים‪ ,‬הכרת‬
‫החוקים‪ ,‬קבלת החלטות נכונות בזמן לחץ‪,‬‬
‫מתן כבוד לזכויות הזולת‪ ,‬סבלנות‪ ,‬למידה‬
‫מניסיון האחרים ‪.‬‬
`,
    audience: [`‫תלמידים מכיתה ז‪ -‬י"ב‬`, `‫הורים‬`],
    tech: false,
    Image: P8,
  },
  {
    id: "25113",
    name: `עדשה צעירה ‪ -‬ צילום‬
‫להעצמה רגשית‬
`,
    desc: `התוכנית מורכבת מהתנסות ולמידה‬
‫מעשית של צילום ע"י אנשי מקצוע‬
‫ומומחים בתחום הצילוםומשולבת עם‬
‫למידה עיונית ‪,‬הלמידה תחולק‬
‫קבוצתית‬
‫וגם‬
‫אישית‬
‫ללמידה‬
‫ובמרחבים שונים לפי צורכי הקבוצה‬
‫במסגרת התוכנית נגע בבעיות רגשית‬
‫והתמודדות עם בעיות חברתיות‬
‫ובבחינת הדרכים להצגת בעיות אלה‬
‫דרך שימוש במצלמה‪ .‬המשתתפים‬
‫ילמדו את שפת הצילום‪ ,‬יתנסו בצילום‬
‫מעשי המבטא את חייהם וחוויותיהם‪,‬‬
‫המשתתפים יובילו פרויקט חברתי או‬
‫תערוכה המציגה את קולם החברתי ‪.‬‬
`,
    audience: [`כיתות ז ‪-‬י"ב‬`, `‫חינוך מיוחד וחינוך רגיל‬`],
    tech: true,
    Image: P9,
  },
  {
    id: "25111",
    name: `טיפול באומנות‬
‫אומנות ‪,‬שפת העצמה שקטה‬
`,
    desc: `טיפול באומנות היא דרך להגשמת מענה‬
‫והינה דרך להעצמה חברתית רגשית‪.‬‬
‫טיפול באומנות הנו מענה חובה להבנה‪,‬‬
‫לקבלה ולהכלת תלמידים עם צרכים‬
‫ייחודיים ושונים ‪,‬תלמידים עם מוגבלויות‪,‬‬
‫תלמידים עם לקויות למידה‪ ,‬תלמידים‬
‫עם קשיים לימודיים‪ ,‬רגשיים והתנהגותיים‬
‫‪ .‬התוכנית מכוונת לקדם את מצבם‬
‫הלימודי‪ ,‬רגשי‪ ,‬חברתי וההתנהגותי של‬
‫התלמידים לאחר תהליך איתור ומיפוי‬
‫שעוברים התלמידים או בני הנוער‬
‫הטיפול עושה שימוש באומנות חזותית (‬
‫פיסול‪ ,‬ציור‪ ,‬הדפס‪ ,‬מריחה ועוד ) ‪.‬‬
‫"טיפול באומנות" מתבסס על האמונה‬
‫בכוחה של היצירה האמנותית מהווה גשר‬
‫ליצירת קשר בין המטפל והמטופל והיא‬
‫מאפשרת שיח בשפה הלא מילולית‬
‫ומעצים התמודדות מול הקשיים הרגשיים‬
‫והחברתיים‪.‬‬
`,
    audience: [`‫כיתות ג' ‪-‬ט'‬`],
    tech: false,
    Image: P10,
  },
  {
    id: "26888",
    name: `סדנאות טיפוליות להעצמת‬
‫ילדים ובני נוער‬
`,
    desc: `התוכנית מציעה מגוון סדנאות‬
‫טיפוליות להעצמת בני נוער‪:‬‬
‫סדנא לעיצוב התנהגות‬
‫טיפול באמנות‬
‫העצמה תלמידים בסדנת פוטו‬
‫תרפיה וטיפול רגשי‬
‫סדנא טיפול בבישול‬
‫סדנת לטיפול בדרמה‬
‫סדנא לטיפול בעזרת בעלי חיים‬
`,
    audience: [`‫כיתות א' ‪-‬י'‬`],
    tech: false,
    Image: P11,
  },
  {
    id: "32885",
    name: `‫מיומנויות לתעסוקה‬`,
    desc: `רכישת מיומנויות תעסוקתיות בעידן‬
‫הטכנולוגי ‪:‬‬
‫תיקון וטיפול בטלפונים ניידים‬
‫וטאבליטים‬
‫נגרות עתידית‬
‫תקשוב ובניית רשתות‬
‫עיצוב גרפי‬
‫איפור ועיצוב שיער‬
‫עיצוב תכשיטים וסטייל‬
‫בישול והכנת סלט‬
`,
    audience: [`‫תלמידים מכיתות ז'עד י"ב‬`],
    tech: true,
    Image: P12,
  },
  {
    id: "32888",
    name: `‫דו קיום למען הקיום‬`,
    desc: `תכנית חינוכית‪-‬ערכית–חברתית אשר‬
‫מיועדת לתלמידים ובניהנוער משני‬
‫המגזרים ‪ .‬בתוכנית ניתנת לבני הנוער‬
‫ההזדמנות להשמיע את קולם ולשתף‬
‫בתחושותיהם ורגשותיהם כלפי האחר‬
‫ותרבותו ‪ .‬המשתתפים יחשפו למגוון‬
‫רחב של פעילויות העצמה כקבוצה‬
‫שמובילה לשינוי בחברה ‪ .‬המשתתפים‬
‫ישמעו סיפורי חיים אישיים של‬
‫מנהיגים ישראלים אשר האמינו בדו‬
‫קיום ופעלו למענו כל זה באווירה של‬
‫אחווה ‪,‬סובלות ‪,‬סבלנות וקבלת האחר‬
‫לא משנה מי הוא ומאיפה הוא מגיע ‪.‬‬
`,
    audience: [`‫תלמידים מכיתות ז'עד י"ב‬`],
    tech: false,
    Image: P13,
  },
  {
    id: "27426",
    name: `מורשת והכרת הארץ‬
‫(אתרים חשובים בארץ)‬
`,
    desc: `סיורים להכרת הארץ והאתרים‬
‫החשובים עבור התלמידים כיעד‬
‫לשמירה על מורשת ארוכת טווח‬
‫והרגשת השייכות‪ ,‬שינוי בדפוסי‬
‫התנהגות והיחס כלפי האתרים‬
‫החשובים בארץ מעלה את המודעות‬
‫ההסטורית העמים שהיו לפנינו‪ ,‬ועוד‪.‬‬
‫התוכנית תועבר בצורת סדנאית‬
‫המשלבת חומרים תיאורטיים לקראת‬
‫סיורים באתר עצמו‪ ,‬לאחר כל מפגש‬
‫תיאורטי יועבר סיור מודרך במטרה‬
‫שהתלמידים יראו את האתר ואת‬
‫הדברים החשובים שקיבלו במפגש‪.‬‬
`,
    audience: [`‫תלמידים מכיתות ד' עד י"ב‬`],
    tech: false,
    Image: P14,
  },
];
